import self from "../img/self.png";
import mock1 from "../img/mock1.png";
import mock2 from "../img/mock2.png";
import mock3 from "../img/mock3.png";
import mock4 from "../img/mock4.png";
import mock5 from "../img/mock5.png";
import mock0 from "../img/mock0.png";

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */

/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
  firstName: "Aditya",
  lastName: "Singh",
  initials: "AS", // the example uses first and last, but feel free to use three or more if you like.
  position: "a Full Stack Developer",
  selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
  gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
  baseColor: colors[0],
  miniBio: [
    // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
    {
      emoji: "☕",
      text: "fueled by coffee",
    },
    {
      emoji: "🌎",
      text: "based in the India",
    },
    {
      emoji: "💼",
      text: "B.tech In Computer Science and Engineering",
    },
    {
      emoji: "📧",
      text: "aditya21official@gmail.com",
    },
  ],
  socials: [
    {
      link: "https://www.facebook.com/asinghkeeprocking",
      icon: "fa fa-facebook",
      label: "facebook",
    },
    {
      link: "https://www.instagram.com/adiisingg/",
      icon: "fa fa-instagram",
      label: "instagram",
    },
    {
      link: "https://github.com/adiii21",
      icon: "fa fa-github",
      label: "github",
    },
    {
      link: "https://www.linkedin.com/in/aditya-singh-93a9ba19a/",
      icon: "fa fa-linkedin",
      label: "linkedin",
    },
    {
      link: "https://twitter.com/adiisingh_",
      icon: "fa fa-twitter",
      label: "twitter",
    },
    // Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
    // Just change the links so that they lead to your social profiles.
  ],
  bio: "Hello! I'm Aditya Singh. I'm a student of computer science and engineering, at Assam Kaziranga University. I enjoy working with Full stack web development. I am compatible with C++ in problem soving and logic building.",
  skills: {
    proficientWith: [
      "C++",
      "javascript",
      "react",
      "git",
      "github",
      "bootstrap",
      "html5",
      "css3",
      "expressJS",
      "nodejs",
      "python",
      "MongoDB",
    ],
  },
  hobbies: [
    {
      label: "reading",
      emoji: "📖",
    },
    {
      label: "3D Animation",
      emoji: "🎭",
    },
    {
      label: "Photography",
      emoji: "🎥",
    },
    {
      label: "Enjoying Music",
      emoji: "🎵",
    },
  ],
  portfolio: [
    {
      title: "Rover Travels",
      live: "https://rovertravel.in/",
      image: mock0,
    },
    {
      title: "AASHROY",
      live: "https://whispering-inlet-07012.herokuapp.com/",
      image: mock1,
    },
    {
      title: "Evergreen Bank",
      live: "https://evergreen-bank.herokuapp.com/",
      image: mock2,
    },
    {
      title: "RazaCoal (Live page available soon)",
      live: "#",
      image: mock3,
    },
    {
      title: "TodoApp JS",
      live: "https://todo-app-vanilla-js-kappa.vercel.app/",
      image: mock5,
    },
    {
      title: "JavaScript DrumKit",
      live: "https://adiii21.github.io/Drum-Kit/",
      image: mock4,
    },
  ],
};
